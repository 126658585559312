<template>
  <div
    class="imagecard"
    :class="{
      processing:
        loadingGarments.length == 0 && dataimage.status !== 'unprocessed',
    }"
  >
    <UButton
      color="white"
      icon="i-heroicons-trash-20-solid"
      class="absolute top-4 right-4 z-[9999] opacity-0 group-hover:opacity-100"
    />

    <UButton
      color="gray"
      variant="main"
      label="Add to Reel"
      icon="i-heroicons-film"
      class="absolute top-4 right-16 z-[9999] !py-1.5 opacity-0 group-hover:opacity-100"
      @click="sendToReel(dataimage.id)"
    />

    <UButton
      color="white"
      icon="i-heroicons-cloud-arrow-down-20-solid"
      class="absolute top-4 left-4 z-[9999] opacity-0 group-hover:opacity-100"
    />

    <a
      draggable="false"
      href="`/detail/${image.pathname.split('.')[0]}`"
      class="shimmer-container relative"
    >
      <img
        draggable="false"
        :src="image"
        class="h-auto w-full max-h-[1430px] model-image rounded-md transition-all duration-200 border-image brightness-[.8] hover:brightness-100 will-change-[filter] object-cover"
      />
      <div
        v-if="dataimage.status == 'processed'"
        class="model-shimmer"
        :style="{
          '--mask-url': `url(${image.replace('.jpg', '_mask.jpg')})`,
        }"
      ></div>

      <div class="glowtwo wipe"></div>
      <div class="glowsparkwrapper"><div class="glowspark"></div></div>
    </a>
    <div
      class="absolute p-2 w-full h-full top-0 left-0 flex items-end gap-2 z-20"
      ref="listRef"
    >
      <UCard
        v-for="garment in garments"
        :key="garment.key"
        :ui="{
          background: 'dark:bg-[#1e1f21]',
          ring: 'ring-0',
          base: 'rounded-lg overflow-hidden h-28 w-20',
          body: 'px-0 py-0',
        }"
        :class="{
          'selected-card-two':
            selectedGarment && selectedGarment.id === garment.id,
          'gradient-border-anim': false,
        }"
        @click="selectGarment(garment)"
      >
        <!-- Photo of the garment -->
        <div class="h-20">
          <img
            draggable="false"
            :src="garment.processed_image_url || garment.cover_image_url"
            alt="Garment Photo"
            :class="{
              'p-2': garment.processed_image_url,
            }"
            class="object-contain w-full h-full dark:bg-[#1e1f21]"
          />
        </div>

        <!-- Garment data in the footer -->
        <template #footer>
          <div class="p-0">
            <h3 class="text-[9px] font-semibold">{{ garment.name }}</h3>
          </div>
        </template>
      </UCard>
    </div>
  </div>
</template>
<script setup>
import { dragAndDrop } from "@formkit/drag-and-drop/vue";
import {
  parents,
  parentValues,
  dragValues,
  setParentValues,
  // handleDragoverParent,
  // handleEnd,
} from "@formkit/drag-and-drop";
import { dropClone } from "~/utils/dropClone.js";
const props = defineProps({
  image: Object,
  dataimage: Object,
});

const {
  public: { api_url },
} = useRuntimeConfig();

async function sendToReel(imageId) {
  const response = await fetch(`${api_url}/reel/generate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      imageId,
    }),
  });
}

const findDuplicates = (values) => {
  const uniqueElements = new Set();
  const duplicates = [];

  values.forEach((item) => {
    if (uniqueElements.has(item)) {
      duplicates.push(item);
    } else {
      uniqueElements.add(item);
    }
  });

  return duplicates;
};

function targetTransferNew({
  currentParent,
  targetParent,
  initialParent,
  draggedNodes,
  initialIndex,
  targetNode,
  state,
}) {
  const draggedValues = draggedNodes.map((x) => x.data.value);

  const currentParentValues = parentValues(
    currentParent.el,
    currentParent.data
  ).filter((x) => !draggedValues.includes(x));

  const targetParentValues = parentValues(targetParent.el, targetParent.data);

  const reset =
    initialParent.el === targetParent.el &&
    targetParent.data.config.sortable === false;

  let targetIndex;

  if (targetNode) {
    if (reset) {
      targetIndex = initialIndex;
    } else if (targetParent.data.config.sortable === false) {
      targetIndex = targetParent.data.enabledNodes.length;
    } else {
      targetIndex = targetNode.data.index;
    }

    targetParentValues.splice(targetIndex, 0, ...draggedValues);
  } else {
    targetIndex = reset ? initialIndex : targetParent.data.enabledNodes.length;

    targetParentValues.splice(targetIndex, 0, ...draggedValues);
  }

  // setParentValues(currentParent.el, currentParent.data, currentParentValues);

  setParentValues(targetParent.el, targetParent.data, targetParentValues);

  if (targetParent.data.config.onTransfer) {
    targetParent.data.config.onTransfer({
      sourceParent: currentParent,
      targetParent,
      initialParent,
      draggedNodes,
      targetIndex,
      state,
    });
  }

  if (currentParent.data.config.onTransfer) {
    currentParent.data.config.onTransfer({
      sourceParent: currentParent,
      targetParent,
      initialParent,
      draggedNodes,
      targetIndex,
      state,
    });
  }
}

function targetTransfer(state, data) {
  const draggedValues = dragValues(state);

  const targetParentValues = parentValues(
    data.targetData.parent.el,
    data.targetData.parent.data
  );

  /*const lastParentValues = parentValues(
      state.lastParent.el,
      state.lastParent.data
    ).filter((x) => !draggedValues.includes(x));*/

  //setParentValues(state.lastParent.el, state.lastParent.data, lastParentValues);

  //alert(JSON.stringify(targetParentValues));

  // Check for duplicates before performing the transfer
  const existingKeys = targetParentValues.map((item) => item.key);
  const newItems = draggedValues.filter(
    (item) => !existingKeys.includes(item.key)
  );

  if (newItems.length === 0) {
    //targetIndex = state.initialIndex;
    return; // Prevent transfer if all items are already present
  }

  const reset =
    state.initialParent.el === data.targetData.parent.el &&
    data.targetData.parent.data.config.sortable === false;

  //alert(state.initialParent.el === data.targetData.parent.el);

  let targetIndex;

  if ("node" in data.targetData) {
    if (reset) {
      targetIndex = state.initialIndex;
    } else if (data.targetData.parent.data.config.sortable === false) {
      targetIndex = data.targetData.parent.data.enabledNodes.length;
    } else {
      targetIndex = data.targetData.node.data.index;
    }

    targetParentValues.splice(targetIndex, 0, ...draggedValues);
  } else {
    targetIndex = reset
      ? state.initialIndex
      : data.targetData.parent.data.enabledNodes.length;

    targetParentValues.splice(targetIndex, 0, ...draggedValues);
  }

  const duplicates = findDuplicates(targetParentValues);

  for (const duplicate of duplicates) {
    if (!("key" in duplicate) || typeof duplicate !== "object") continue;
    const index = targetParentValues.indexOf(duplicate);
    const newKey = `${duplicate.key}-${Math.random()
      .toString(36)
      .substring(2, 15)}`;

    //alert(newKey);

    targetParentValues[index] = {
      ...targetParentValues[index],
      key: newKey,
    };
  }

  setParentValues(
    data.targetData.parent.el,
    data.targetData.parent.data,
    targetParentValues
  );
}

const targetClone = (parent) => {
  const parentData = parents.get(parent);

  if (!parentData) return;

  return {
    setup() {
      parentData.config.performTransfer = targetTransferNew;
    },
  };
};

const mainImageSrc = ref(`/images/${props.image.pathname}`);
const loadingGarments = ref([]);
const processedGarments = ref([]);
const selectedGarment = ref(null);
const garments = ref([]);
const listRef = ref();

dragAndDrop({
  parent: listRef,
  values: garments,
  // dropZone: true,
  group: "items",

  dropZoneParentClass: "glowing",
  /* handleDragoverParent: function (event) {
    //console.log(event);
    handleDragoverParent(event);
  },
  handleEnd: function (event) {
    //alert("hello");
    handleEnd(event);
  },*/
  // sortable: true,
  // dropZoneClass: "dragging",
  plugins: [dropClone()],
});

watch(garments, async (newGarments, oldGarments) => {
  const addedGarments = newGarments.filter(
    (newGarment) =>
      !oldGarments.some((oldGarment) => oldGarment.id === newGarment.id)
  );
  for (const garment of addedGarments) {
    alert("hello");
    alert(JSON.stringify(garment));
    // alert(props.dataimage.id);
    if (garment.data) {
      await processLocation(garment);
    } else {
      await processGarment(garment);
    }
  }
});

async function processGarment(garment) {
  loadingGarments.value.push(garment.id);
  try {
    const response = await fetch(`${api_url}/gallery/generate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        modelPhotoId: props.dataimage.id,
        garmentId: garment.id,
      }),
    });
    //const data = await response.json();
    //processedGarments.value.push({ id: garment.id, url: data.output_url });
  } catch (error) {
    console.error("Error processing garment:", error);
  } finally {
    /* loadingGarments.value = loadingGarments.value.filter(
        (id) => id !== garment.id
      );*/
  }
}

async function processLocation(location) {
  loadingGarments.value.push(location.id);
  try {
    const response = await fetch(`${api_url}/locations/generate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        galleryId: props.dataimage.id,
        locationId: location.id,
      }),
    });
    //const data = await response.json();
    //processedGarments.value.push({ id: garment.id, url: data.output_url });
  } catch (error) {
    console.error("Error processing garment:", error);
  } finally {
    /* loadingGarments.value = loadingGarments.value.filter(
        (id) => id !== garment.id
      );*/
  }
}

const selectGarment = (garment) => {
  selectedGarment.value = garment;
  // Simulate sending the image to the AI and receiving a new image URL
  mainImageSrc.value = `/images/processed/${garment.key}.jpg`;
};
</script>
<style scoped lang="postcss">
.shimmer-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.model-shimmer {
  mask-mode: luminance;
  position: absolute;
  display: block;
  width: 100%;
  z-index: 20;
  height: 100%;
  top: 0;
  left: 0;
  mask-image: var(--mask-url);
  -webkit-mask-image: var(--mask-url);
  mask-size: cover;
  -webkit-mask-size: cover;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
}

.model-shimmer:before {
  content: "";
  z-index: 30;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 75%
  );
  background-size: 200% 100%;
  animation: shimmer 2s linear;
  background-position: 300% 0;
  pointer-events: none;
}

.imagecard:hover .model-shimmer:before,
a:hover .model-shimmer:before {
  animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.dragging {
  border: 2px dashed #4b5563 !important;
  background-color: #2d3748 !important;
  border-radius: 0.375rem !important;
  transition: border-color 0.2s ease !important;
}
.selected-card {
  border: 1px solid #4b5563; /* Customize the color as needed */
}
.gradient-border-anim {
  --borderWidth: 1px;
  background: #1d1f20;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border-anim:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.glowcontainer {
  width: 300px;
  height: 450px;
  background: gray;

  position: relative;
}

.glowsparkwrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  mix-blend-mode: multiply; /* Blend the entire container */
  isolation: isolate; /* Create a new stacking context */
}

.glowspark {
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  filter: blur(9px);
  opacity: 1;
  transition: all 0.5s;
}

.processing .glowspark {
  opacity: 0;
}

.glowspark:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  background: #fff;
  inset: 10px;
  border-radius: 5px;
}

.glowspark:before {
  content: "";
  width: 200%;
  height: 200%;
  z-index: -1;
  aspect-ratio: 1;
  inset: 50% auto auto 50%;
  position: absolute;
  translate: -50% -50%;
  animation: lazy 6s infinite linear;
  background: conic-gradient(
    transparent,
    rgba(0, 0, 255, 1) 90deg,
    transparent 180deg
  );
}

@keyframes lazy {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.glowtwo {
  width: 400px;
  height: 250px;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  box-shadow: inset 0 0 50px rgba(255, 0, 255, 0.5),
    inset 0 0 100px rgba(0, 0, 255, 0.5), inset 0 0 150px rgba(255, 0, 255, 0.5);

  transition: all 0.5s;
}

.processing .glowtwo {
  box-shadow: inset 0 0 0px rgba(255, 0, 255, 0.5),
    inset 0 0 0px rgba(0, 0, 255, 0.5), inset 0 0 0px rgba(255, 0, 255, 0.5);
}

.glowtwo:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  filter: blur(20px);
  border: 15px solid;
  border-image: linear-gradient(
      90deg,
      rgba(255, 0, 255, 1),
      rgba(0, 0, 255, 1),
      rgba(255, 0, 255, 1)
    )
    10;

  transition: all 0.5s;
}

.processing .glowtwo:before {
  border: 0;
}

.glowing {
  width: 800px;
  height: 450px;

  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  transition: all 0.5s;
  box-shadow: inset 0 0 50px rgba(255, 0, 255, 0.5),
    inset 0 0 100px rgba(0, 0, 255, 0.5), inset 0 0 150px rgba(255, 0, 255, 0.5);
}

.glow {
  width: 800px;
  height: 450px;
  background: black;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  box-shadow: inset 0 0 50px rgba(255, 0, 255, 0.5),
    inset 0 0 100px rgba(0, 0, 255, 0.5), inset 0 0 150px rgba(255, 0, 255, 0.5);
}

.glow:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 255, 1),
    rgba(0, 0, 255, 1),
    rgba(255, 0, 255, 1)
  );
  z-index: 1;
  filter: blur(30px);
}

.glow:after {
  content: "";
  position: absolute;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  background: black;
  filter: blur(50px);
  z-index: 2;
}

.wipe {
  transition: all 600ms cubic-bezier(0, 0.55, 0.45, 1);
}
</style>
